import request from '@/utils/request';

export function fetchList() {
  return request({
    url: '/v1/settings/getAll',
    method: 'get',
  });
}

export function update(data) {
  return request({
    url: 'v1/settings/updateSetting',
    method: 'patch',
    data,
  });
}

export function restoreDefault() {
  return request({
    url: 'v1/settings/restoreDefault',
    method: 'post',
  });
}
