<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Settings</span>
      <el-button class="btn-add" @click="handleRestore()" size="mini">
        Restore
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table
        ref="redisAppTable"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Key" align="center">
          <template slot-scope="scope">{{ scope.row.key }}</template>
        </el-table-column>
        <el-table-column label="Value" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.value"
              v-if="typeof scope.row.value == 'string'"
            ></el-input>
            <el-input-number
              v-model="scope.row.value"
              v-if="typeof scope.row.value == 'number'"
            ></el-input-number>
            <el-select
              v-model="scope.row.value"
              v-if="typeof scope.row.value == 'boolean'"
            >
              <el-option
                v-for="item in [true, false]"
                :key="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Operations" align="center">
          <template slot-scope="scope">
            <p>
              <el-button type="primary" @click="updateValue(scope.row)"
                >Update</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { fetchList, update, restoreDefault } from '@/api/settings';

export default {
  name: 'redis',
  data() {
    return {
      list: [],
      listLoading: true,
      newEntry: { key: null, value: null },
      newEntryType: null,
      createVisible: false,
      typeOptions: ['number', 'string', 'boolean'],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      fetchList().then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    updateValue(entry) {
      update(entry).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
    },
    handleRestore() {
      this.$confirm('Restore Default?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        restoreDefault().then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    // handleCreate() {
    //   this.createVisible = true;
    //   this.newEntry = { key: null, value: null };
    //   this.newEntryType = null;
    // },
    // submitCreate() {
    //   update(this.newEntry).then(() => {
    //     this.$message({
    //       message: 'Success',
    //       type: 'success',
    //       duration: 1000,
    //     });
    //     this.createVisible = false;
    //     this.getList();
    //   });
    // },
  },
};
</script>
